<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <!-- <section class="overflow-hidden relative" style="height: 100vh">
      <div class="login-screeen-shape-1"></div>
      <div class="login-screen-header"></div>
      <div class="login-screen-footer"></div>
    </section> -->
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/otoride/logo/brand/breeze.png" alt="Breeze Logo" />
        <div class="mt-5 login-area">
          <div class="px-12 my-5 w-full">
            <h1
              class="mt-16 mb-10 text-lg font-bold sm:text-xl md:text-2xl"
              style="color: #505458"
            >
              Login To Start Session
            </h1>
            <div class="h-6 text-center text-red-500">
              <transition name="fade">
                <div
                  class="text-center text-red-500"
                  v-if="reqStatus === 'failed'"
                >
                  Invalid Credentials
                </div>
              </transition>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                  name="username"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <username-field v-model="username" />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>

                <ValidationProvider
                  name="password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field v-model="password" />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
                <div class="pb-6 mt-6">
                  <t-button class="w-full" type="submit">
                    Login
                  </t-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- end:component -->
    </section>
  </div>
</template>

<script>
import UsernameField from '@/components/form/UsernameField'
import PasswordField from '@/components/form/PasswordField'

export default {
  components: {
    UsernameField,
    PasswordField,
  },
  data() {
    return {
      username: '',
      password: '',
      isLoadig: false,
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters['auth/status']
    },
    reqStatus: function() {
      return this.$store.getters['auth/reqStatus']
    },
    isLoading: function() {
      return this.$store.getters['auth/reqStatus'] === 'processing'
        ? true
        : false
    },
    toRedirectRoute: function() {
      return this.$store.getters['navigation/afterLoginRedirectRoute']
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch('auth/login', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          // console.log({ org: this.$store.getters["auth/organizationInfo"] });
          // this.$router.push(this.toRedirectRoute)
          if (
            this.$permissions.filter((item) => item.name === 'Dashboard').length
          ) {
            this.$router.push('/')
          } else {
            this.$router.push(`/users/${this.$user.id}/profile`)
          }
          // location.reload() // first time hard refresh after login
        })
        .catch(() => {
          console.log('Invalid credentials')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
// todo: @use "sass:math";

$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 50%;
  left: 50%;
  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  border-radius: 0.375rem;
  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}

.login-logo {
  width: $logo-width;
  height: $logo-height;
  object-fit: contain;
  z-index: 100;
}

.login-screen-header {
  background-image: url('~@/assets/otoride/auth/auth-header-v1-1x.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  height: 60vh;
}

.login-screeen-shape-1 {
  position: absolute;
  border: solid 38px #83ff00;
  right: -58px;
  top: -45px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.login-screen-footer {
  height: 40vh;
  width: 100%;
  // border: 1px solid red;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 20rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/otoride/auth/auth-footer-v2-1x.png');
}
</style>
